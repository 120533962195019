import React, { useState, Component } from "react";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./style.css";
const chartData = [
	{ name: "Node.js", w: "47.12%" },
	{ name: "React.js", w: "42.62%" },
	{ name: "jQuary", w: "28.57%" },
	{ name: "Express", w: "22.99%" },
	{ name: "Angular", w: "20.39%" },
	{ name: "Vue.js", w: "18.82%" },
	{ name: "ASP.NET Core", w: "18.59%" },
	{ name: "ASP.NET", w: "14.9%" },
	{ name: "Django", w: "14.65%" },
	{ name: "Flask", w: "14.64%" },
	{ name: "Next.js", w: "13.52%" },
	{ name: "Laravel", w: "9.45%" },
	{ name: "Angular.js", w: "8.99%" },
	{ name: "FastAPI", w: "6.02%" },
	{ name: "Ruby on Rails", w: "5.83%" },
	{ name: "Svelte", w: "4.58%" },
	{ name: "Blazor", w: "4.46%" },
	{ name: "Nuxt.js", w: "3.83%" },
	{ name: "Symfony", w: "3.58%" },
	{ name: "Gatsby", w: "3.46%" },
	{ name: "Drupal", w: "2.22%" },
	{ name: "Phoenix", w: "2.13%" },
	{ name: "Fastify", w: "1.85%" },
	{ name: "Deno", w: "1.69%" },
	{ name: "Play Framework", w: "0.82%" },
];
const chartDataJava = [
	{ name: "JavaScript", w: "65.36%" },
	{ name: "HTML/CSS", w: "55.08%" },
	{ name: "SQL", w: "49.43%" },
	{ name: "Python", w: "48.07%" },
	{ name: "TypeScript", w: "34.83%" },
	{ name: "Java", w: "33.27%" },
	{ name: "Bash/Shell", w: "29.07%" },
	{ name: "C#", w: "27.98%" },
	{ name: "C++", w: "22.55%" },
	{ name: "PHP", w: "20.87%" },
	{ name: "C", w: "19.24%" },
	{ name: "PowerShell", w: "12.07%" },
	{ name: "Go", w: "11.15%" },
	{ name: "Rust", w: "9.32%" },
	{ name: "Kotlin", w: "9.16%" },
	{ name: "Dart", w: "6.54%" },
	{ name: "Ruby", w: "6.05%" },
	{ name: "Assembly", w: "5.47%" },
	{ name: "Swift", w: "4.91%" },
	{ name: "R", w: "4.66%" },
	{ name: "VBA", w: "4.48%" },
	{ name: "Matlab", w: "4.1%" },
	{ name: "Lua", w: "4.03%" },
	{ name: "Groovy", w: "3.32%" },
	{ name: "Delphi", w: "3.25%" },
];
const imagePerRow = 5;
export default ({ data }) => {
	const [next, setNext] = useState(imagePerRow);
	const handleMoreImage = () => {
		setNext(next + imagePerRow);
	};
	return (
		<Box sx={{ textAlign: "center" }}>
			{data == 1
				? chartData.slice(0, next)?.map((item, i) => (
						<Box key={i}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									gap: 1,
								}}
							>
								<Typography
									id="chartname"
									sx={{
										fontWeight: "bold",
										width: "130px",
										textAlign: "end",
										fontSize: {
											xl: "16px",
											lg: "16",
											md: "14px",
											sm: "12px",
											xs: "12px",
										},
									}}
								>
									{item.name}
								</Typography>
								<Box
									sx={{
										width: `${item.w}`,
										bgcolor: "#3AB548FF",
										display: "flex",
										justifyContent: "end",
										position: "relative",
										my: "1%",
									}}
								>
									<Typography
										sx={{
											position: "relative",
											right: "-60px",
											fontWeight: "bold",
										}}
									>
										{item.w}
									</Typography>
								</Box>
							</Box>
						</Box>
				  ))
				: chartDataJava.slice(0, next)?.map((item, i) => (
						<Box key={i}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									gap: 1,
								}}
							>
								<Typography
									id="chartname"
									sx={{
										fontWeight: "bold",

										textAlign: "end",
										fontSize: {
											xl: "16px",
											lg: "16",
											md: "14px",
											sm: "12px",
											xs: "12px",
										},
									}}
								>
									{item.name}
								</Typography>
								<Box
									sx={{
										width: `${item.w}`,
										bgcolor: "#3AB548FF",
										display: "flex",
										justifyContent: "end",
										position: "relative",
										my: "1%",
									}}
								>
									<Typography
										sx={{
											position: "relative",
											right: "-60px",
											fontWeight: "bold",
										}}
									>
										{item.w}
									</Typography>
								</Box>
							</Box>
						</Box>
				  ))}
			{next < chartData?.length && (
				<Button
					onClick={handleMoreImage}
					variant="outlined"
					sx={{
						textTransform: "none",
						fontWeight: 700,
						// background: "#3AB548FF",
						color: "#3AB548FF",
						borderColor: "#3AB548FF",
						my: "2px",
						":hover": {
							color: "#3AB548FF",
							borderColor: "#3AB548FF",
						},
					}}
					endIcon={<AddIcon sx={{ color: "#3AB548FF" }} />}
				>
					Ko'proq
				</Button>
			)}
		</Box>
	);
};
