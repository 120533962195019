import React, { useState, useEffect } from "react";

import { KeyboardArrowUp } from "@mui/icons-material";
import { Box, IconButton, Container } from "@mui/material";

const Scroll = ({ showBelow }) => {
	const [show, setShow] = useState(showBelow ? false : true);

	const handleScroll = () => {
		if (window.pageYOffset > showBelow) {
			if (!show) setShow(true);
		} else {
			if (show) setShow(false);
		}
	};

	const handleClick = () => {
		window[`scrollTo`]({ top: 0, behavior: `smooth` });
	};

	useEffect(() => {
		if (showBelow) {
			window.addEventListener(`scroll`, handleScroll);
			return () => window.removeEventListener(`scroll`, handleScroll);
		}
	});

	return (
		<div>
			{show && (
				<Container maxWidth="lg" sx={{ position: "relative" }}>
					<IconButton
						onClick={handleClick}
						sx={{
							zIndex: 2,
							position: "fixed",
							right: {
								xl: "50px",
								lg: "50px",
								md: "30px",
								sm: "20px",
								xs: "15px",
							},
							bottom: "8vh",
							backgroundColor: "#3AB548FF",
							color: "white",
							":hover": {
								backgroundColor: "#3AB548FF",
								color: "white",
							},
						}}
						aria-label="to top"
						component="span"
					>
						<KeyboardArrowUp />
					</IconButton>
				</Container>
			)}
		</div>
	);
};

export default Scroll;
