import { Box, Button, Container, Grid, List, Typography } from "@mui/material";
import React from "react";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import "./style.css";
import telegram from "../../assets/image/telegram.397bebf.svg";
import instagram from "../../assets/image/instagram-5.svg";
import linkedin from "../../assets/image/linkedin-icon-2.svg";
import youtube from "../../assets/image/youtube-icon.svg";
const DataSmm = [
	{
		rasm: telegram,
		textOne: "Telegram kanalimiz",
		xl: 12,
		md: 12,
		icon: <NorthEastIcon color="primary" fontSize="small" />,
		desp: "Telegram kanalimizga qo'shiling va yangiliklardan habardor bo'ling",
		link: "https://t.me/codemyuz",
	},
	{
		// rasm: "https://www.freepnglogos.com/uploads/logo-ig-png/logo-ig-instagram-new-logo-vector-download-13.png",
		rasm: instagram,
		textOne: "Instagram",
		xl: 6,
		md: 12,
		icon: <NorthEastIcon color="primary" fontSize="small" />,
		desp: "Instagram tarmog'imizda yanada yangi ma'lumotlar sizni kutmoqda",
		link: "https://www.instagram.com/codemyuz/",
	},
	{
		// rasm: "https://www.freepnglogos.com/uploads/linkedin-logo-design-30.png",
		rasm: linkedin,
		textOne: "Linkedin",
		xl: 6,
		md: 12,
		icon: <NorthEastIcon color="success" fontSize="small" />,
		desp: "Dasturchilar uchun mo'jallangan saytda bizni kuzating",
		link: "https://www.linkedin.com/company/codemy-uz/",
	},
	{
		// rasm: "https://www.freepnglogos.com/uploads/youtube-icon-logo-7.png",
		rasm: youtube,
		textOne: "You tube",

		xl: 12,
		md: 12,
		icon: <NorthEastIcon color="primary" fontSize="small" />,
		desp: "Youtubeda ham bizni kuzatishni davom eting",
		link: "https://www.youtube.com/channel/UCTukhmPBn9hEaDwL38f1LMQ",
	},
];
export default () => {
	return (
		<Box
			id="meetthebg"
			sx={{
				px: { xl: "13%", lg: "11%", md: "0%", sm: "0", xs: 0 },
			}}
		>
			<Container maxWidth="lg" id="meetdiv" data-aos="fade-up">
				<Grid container spacing={4}>
					<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
						<Box
							className="leftGrid"
							sx={{
								width: "100%",
							}}
						>
							<Typography id="textbold">
								Bizni ijtimoiy tarmoqlarda ham kuzating
							</Typography>
							<Typography id="textdes">
								Akademyamiz haqida ma'lumotlarni endilikda ijtimoiy tarmoqlarda
								ham kuzating
							</Typography>
						</Box>
					</Grid>
					<Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
						<Grid container spacing={4}>
							{DataSmm.map((item, i) => (
								<Grid
									item
									xl={item.xl}
									lg={item.xl}
									md={item.xl}
									sm={item.sm}
									xs={item.sm}
									sx={{ width: "100%", height: "100%" }}
									key={i + 1}
								>
									<Button
										id="www"
										href={item.link}
										target="_blank"
										sx={{ textDecoration: "none" }}
										data-aos="fade-up"
									>
										<img src={item.rasm} alt="" width="64px" />
										<Box>
											<Typography id="textname">
												{item.textOne}
												{item.icon}
											</Typography>
											<Typography id="textdesss">{item.desp}</Typography>
										</Box>
									</Button>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
				<Box
					id="shar1"
					sx={{ display: { md: "flex", sm: "flex", xs: "none" } }}
				></Box>
				<Box id="shar2"></Box>
			</Container>
		</Box>
	);
};
