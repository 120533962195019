import React, { useEffect, useState } from "react";
import "./footer.css";

import {
	Box,
	Container,
	Grid,
	IconButton,
	Link,
	Typography,
} from "@mui/material";

import GitHubIcon from "@mui/icons-material/GitHub";
import AirlinesIcon from "@mui/icons-material/Airlines";
import TwitterIcon from "@mui/icons-material/Twitter";
import MovieIcon from "@mui/icons-material/Movie";
import RedditIcon from "@mui/icons-material/Reddit";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default ({ pag }) => {
	return (
		<Box className="Foot">
			<Box className="footer">
				<Container maxWidth="lg">
					<Grid
						container
						spacing={2}
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-around",
						}}
						data-aos="fade-up"
					>
						<Grid xs={12} sm={12} md={2} lg={3} item sx={{ marginY: "2%" }}>
							<Box
								sx={{
									display: "flex",
									gap: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },
									flexDirection: "column",
								}}
							>
								{/* <img src={logo} alt="" width="90%" color="white" /> */}
								<Link className="linkbold">Codemy Dasturlash Akademiyasi</Link>
								<Typography className="linkold">
									Kelajagingizni biz bilan yarating!
								</Typography>
								<Typography className="linkold">
									Aloqa uchun:
									<a
										href="tel:+998975903309"
										style={{ textDecorationLine: "none" }}
									>
										<Typography className="linkold">
											+998 97 590 33 09
										</Typography>
									</a>
									<a
										href="tel:+998901658486"
										style={{ textDecorationLine: "none" }}
									>
										<Typography className="linkold">
											+998 90 165 84 86
										</Typography>
									</a>
								</Typography>
							</Box>
						</Grid>
						<Grid xs={6} sm={3} md={2} lg={3} item sx={{ marginY: "2%" }}>
							<Box
								sx={{
									display: "flex",
									gap: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },
									flexDirection: "column",
								}}
							>
								{/* <img src={logo} alt="" width="90%" color="white" /> */}
								<Link className="linkbold" href={pag ? "/" : "#Frontend"}>
									Birgalikda o'rganing
								</Link>
								<Typography className="linkold">Frontend</Typography>
								<Typography className="linkold">Css</Typography>
								<Typography className="linkold">React</Typography>
								<Typography className="linkold">Backend</Typography>
								<Typography className="linkold">Node js</Typography>
								<Typography className="linkold">MongoDB</Typography>
							</Box>
						</Grid>
						<Grid xs={6} sm={3} md={2} lg={2} item sx={{ marginY: "2%" }}>
							<Box
								variant="h6"
								fontWeight="bold"
								sx={{
									display: "flex",
									gap: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },
									flexDirection: "column",
								}}
							>
								<Link className="linkbold" href={pag ? "/" : "#natija"}>
									{" "}
									Bizning natijalar
								</Link>

								<Typography className="linkold">Bizni tanlaganlar</Typography>
								<Typography className="linkold">Bitirganlar</Typography>
								<Typography className="linkold">
									Ish bilan <br /> taminlanganlar
								</Typography>
							</Box>
						</Grid>
						<Grid xs={6} sm={3} md={2} lg={2} item sx={{ marginY: "2%" }}>
							<Box
								variant="h6"
								fontWeight="bold"
								sx={{
									display: "flex",
									gap: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },
									flexDirection: "column",
								}}
							>
								<Link className="linkbold" href={pag ? "" : "#sortbox"}>
									{" "}
									Video darslar
								</Link>

								<Typography className="linkold">HTML</Typography>
								<Typography className="linkold">Css</Typography>
								<Typography className="linkold">React</Typography>
								<Typography className="linkold">Javascript</Typography>
							</Box>
						</Grid>
						<Grid xs={6} sm={3} md={2} lg={2} item sx={{ marginY: "2%" }}>
							<Box
								variant="h6"
								fontWeight="bold"
								sx={{
									display: "flex",
									gap: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },
									flexDirection: "column",
								}}
							>
								<Link className="linkbold" href={pag ? "/" : "#ustoz"}>
									{" "}
									Codemy Ustozlari
								</Link>
								<Link className="linkbold" href={pag ? "/" : "#oquv"}>
									{" "}
									O'quv yili
								</Link>
								<Link className="linkbold" href={pag ? "/" : "#manzil"}>
									Manzil
								</Link>
								<Link className="linkbold" href={pag ? "/" : "#manzil"}>
									Ro'yxatdan o'tish
								</Link>
							</Box>
						</Grid>
					</Grid>
					<Box sx={{ textAlign: "center", my: "5%" }}>
						<Typography>Bizni ijtimoiy tarmoqlarda ham kuzating</Typography>
						<Typography>
							Akademyamiz haqida ma'lumotlarni endilikda ijtimoiy tarmoqlarda
							ham kuzating
						</Typography>
						<Box
							style={{
								display: "flex",
								gap: 2,
								justifyContent: "center",
							}}
						>
							<IconButton
								aria-label="fingerprint"
								href="https://t.me/codemyuz"
								target="_blank"
							>
								<TelegramIcon id="fooicon" />
							</IconButton>
							<IconButton
								aria-label="fingerprint"
								href="https://www.instagram.com/codemyuz/"
								target="_blank"
							>
								<InstagramIcon id="fooicon" />
							</IconButton>
							<IconButton
								aria-label="fingerprint"
								href="https://www.linkedin.com/company/codemy-uz/"
								target="_blank"
							>
								<LinkedInIcon id="fooicon" />
							</IconButton>
							<IconButton
								aria-label="fingerprint"
								href="https://www.youtube.com/channel/UCTukhmPBn9hEaDwL38f1LMQ"
								target="_blank"
							>
								<YouTubeIcon id="fooicon" />
							</IconButton>
						</Box>
					</Box>
				</Container>
			</Box>
		</Box>
	);
};
