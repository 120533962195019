import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Boshbulim from "../../components/Boshbulim";
import Fulstek from "../../components/Fulstek";
import Tarmoqlar from "../../components/Tarmoqlar";
import Natija from "../../components/Natija";
import Videolar from "../../components/Videolar";
import UquvYili from "../../components/UquvYili";
import Ustozlar from "../../components/Ustozlar";
import Manzil from "../../components/Manzil";
import SungiBulim from "../../components/SungiBulim";
import AOS from "aos";
import "aos/dist/aos.css";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Button, IconButton } from "@mui/material";
import { Box, Zoom, Fab } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Scroll from "../../components/Scroll";
import Aloqa from "../../components/Aloqa";
export default () => {
	useEffect(() => {
		AOS.init({ duration: 700 });
		AOS.refresh();
	}, []);
	return (
		<>
			<Navbar />
			<Boshbulim />
			<Fulstek />
			<Tarmoqlar />
			<Natija />
			<Videolar />
			<UquvYili />
			<Ustozlar />
			<Manzil />
			<Aloqa />
			<SungiBulim />
			<Scroll showBelow={250} />
		</>
	);
};
