import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Filler,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,

	Filler,
	Legend
);

export const options = {
	tooltips: {
		enabled: "false",
	},

	plugins: {
		legend: false,
	},
};

const labels = [
	"Boshlanish",
	"1-oy",
	"2-oy",
	"3-oy",
	"4-oy",
	"5-oy",
	"6-oy",
	"7-oy",
	"8-oy",
	"9-oy",
	"10-oy",
];

export const data = {
	labels,
	datasets: [
		{
			fill: "false",

			data: [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
			borderColor: "rgb(53, 162, 235)",
			backgroundColor: "rgba(53, 162, 235, 0.5)",
		},
	],
};

export default () => {
	return <Line options={options} data={data} width="1056px" height="528px" />;
};
