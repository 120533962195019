import Home from "../Page/Home";
import MalimotPage from "../Page/MalimotPage";

export const mainRoutes = [
	{
		// element: <Home />,
		children: [
			{ path: "/", element: <Home /> },
			{ path: "/malumot", element: <MalimotPage /> },
		],
	},
];
