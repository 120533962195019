import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./style.css";

import logo from "../../assets/image/CodemyLogo.png";
import xato from "../../assets/image/3426526.jpg";

const data2 = [
	{
		images: "https://www.youtube.com/embed/3LZXOYSuhS8",
		logoimg: "https://cdn.worldvectorlogo.com/logos/css-3.svg",
		title: "#9 CSS haqida",
		desc: "Css haqida boshlang'ich darslar",
	},
	{
		images: "https://www.youtube.com/embed/RCitzErdI0M",
		logoimg: "https://decimalchain.com/_nuxt/img/telegram.397bebf.svg",
		title: "Telegram ijtimoiy tarmog'ida bot orqali test yaratish",
		desc: "Telegram haqida boshlang'ich darslar",
	},
];
const data = [
	{
		images: "https://www.youtube.com/embed/k8Jd36HlYmY",
		logoimg: "https://cdn.worldvectorlogo.com/logos/html-1.svg",

		title: "#1 HTML haqida",
		desc: "HTML haqida boshlang'ich darslar",
	},
	{
		images: "https://www.youtube.com/embed/PlFcQUt6-7s",

		logoimg: "https://cdn.worldvectorlogo.com/logos/html-1.svg",
		title: "#2 Ma'lumot joylash",
		desc: "HTML haqida boshlang'ich darslar",
	},
	{
		images: "https://www.youtube.com/embed/40iuhYjgrpw",

		logoimg: "https://cdn.worldvectorlogo.com/logos/html-1.svg",
		title: "#3 Web sahifada Shrift xususiyatlari",
		desc: "HTML haqida boshlang'ich darslar",
	},
	{
		images: "https://www.youtube.com/embed/Rm201tdEwWY",

		logoimg: "https://cdn.worldvectorlogo.com/logos/html-1.svg",
		title: "#4 Web sahifaga rasm joylashtirish",
		desc: "HTML haqida boshlang'ich darslar",
	},
];

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box id="sortbox">
			<Box className="exchanges" data-aos="fade-up">
				<Box className="exchangeshead">
					<Typography className="exchangestext">Videolar</Typography>
					<Box
						className="exchangestext2"
						sx={{ display: "flex", alignItems: "center", gap: 1 }}
					>
						Video darslarimiz{" "}
						<img src={logo} width="50px" alt="" className="exchangesimg" />{" "}
					</Box>
				</Box>
				<Box className="exchangestabs">
					<Tabs
						value={value}
						onChange={handleChange}
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
						TabIndicatorProps={{
							style: { backgroundColor: "#3ab54a" },
						}}
						className="exchangestabs2"
					>
						<Tab
							className="exchangextabsitems extab"
							label="HTML"
							{...a11yProps(0)}
							style={{ minWidth: "70px" }}
						/>
						<Tab
							className="exchangextabsitems extab"
							label="CSS"
							{...a11yProps(1)}
							style={{ minWidth: "70px" }}
						/>
						<Tab
							className="exchangextabsitems extab"
							label="JS"
							{...a11yProps(2)}
							style={{ minWidth: "70px" }}
						/>
						<Tab
							className="exchangextabsitems"
							label="REACT"
							{...a11yProps(3)}
							style={{ minWidth: "70px" }}
						/>
					</Tabs>

					<TabPanel value={value} style={{ padding: "0px" }} index={0}>
						<Box className="CardsItemsss">
							{data.map((item, index) => {
								return (
									<Box
										key={index}
										className="CardItemm"
										style={{
											border: "10px solid lime",
										}}
									>
										<iframe
											id="existing-iframe-example"
											width="100%"
											className="youtube"
											// src="https://www.youtube.com/embed/k8Jd36HlYmY"
											src={item.images}
											frameBorder="0"
										/>

										<Box className="cardBottompart">
											<img src={item.logoimg} className="LogoCard" alt="" />
											<Box>
												<Typography className="cardTitle">
													{item.title}
												</Typography>
												<Typography className="cardDesc">
													{item.desc}
												</Typography>
											</Box>
											<Box id="tablebox2">
												<ArrowForwardIcon
													className="arrowIcon"
													style={{ width: "24px", height: "24px" }}
												/>
											</Box>
										</Box>
									</Box>
								);
							})}{" "}
						</Box>
					</TabPanel>
					<TabPanel value={value} style={{ padding: "0px" }} index={1}>
						<Box className="CardsItemsss">
							{data2.map((item, index) => {
								return (
									<Box
										key={index}
										className="CardItemm"
										style={{
											border: "10px solid red",
											// backgroundImage: `url(${item.bgimages})`,
										}}
									>
										<Box>
											<iframe
												id="existing-iframe-example"
												width="100%"
												className="youtube"
												src={item.images}
												frameBorder="0"
											/>
										</Box>
										<Box className="cardBottompart">
											<img src={item.logoimg} className="LogoCard" alt="" />

											<Box>
												<Typography className="cardTitle">
													{item.title}
												</Typography>
												<Typography className="cardDesc">
													{item.desc}
												</Typography>
											</Box>
											<Box>
												<ArrowForwardIcon
													className="arrowIcon"
													style={{ width: "24px", height: "24px" }}
												/>
											</Box>
										</Box>
									</Box>
								);
							})}{" "}
						</Box>
					</TabPanel>
					<TabPanel value={value} style={{ padding: "0px" }} index={2}>
						<Box>
							<Typography variant="h5" sx={{ textAlign: "center" }}>
								JavaScript bo'yicha video darsliklarimiz endilikda
								tayyorlanmoqda...
							</Typography>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<img src={xato} alt="" width="50%" />
							</Box>
						</Box>
					</TabPanel>
					<TabPanel value={value} style={{ padding: "0px" }} index={3}>
						<Box>
							<Typography variant="h5" sx={{ textAlign: "center" }}>
								React bo'yicha video darsliklarimiz endilikda tayyorlanmoqda...
							</Typography>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<img src={xato} alt="" width="50%" />
							</Box>
						</Box>
					</TabPanel>
				</Box>
			</Box>
		</Box>
	);
};
