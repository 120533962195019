import { Box, Typography, Container } from "@mui/material";
import React from "react";
import "../Manzil/style.css";

import logo from "../../assets/image/CodemyLogo.png";

export default () => {
	return (
		<Container maxWidth="lg">
			<Box sx={{ width: "100%", px: { md: "10px", lg: "45px", xl: "45px" } }}>
				<Box className="TopPartG">
					<Box className="TextCenBoxG">
						<img className="GetImg001" src={logo} alt="" />
						<Box>
							<Typography
								sx={{
									fontSize: {
										xs: "24px",
										sm: "24px",
										md: "30px",
										lg: "36px",
									},
									fontWeight: "bold",
								}}
							>
								Codemy
							</Typography>

							<Typography>Dasturlash Akademiyasi</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							flexDirection: {
								xs: "row-reverse",
								sm: "row-reverse",
								md: "row",
								lg: "row",
								xl: "row",
							},
						}}
					>
						<Box
							sx={{
								display: "flex",

								flexDirection: {
									xs: "column-reverse",
									sm: "column-reverse",
									md: "column",
									lg: "column",
									xl: "column",
								},
							}}
						>
							<Typography
								sx={{
									fontSize: {
										xs: "18px",
										sm: "20px",
										md: "20px",
										lg: "24px",
									},
								}}
							>
								+998 97 590 33 09
							</Typography>
							<Typography
								sx={{
									textAlign: {
										xs: "start",
										sm: "start",
										md: "end",
										lg: "end",
										xl: "end",
									},
									fontSize: {
										xs: "16px",
										sm: "16px",
										md: "18px",
										lg: "22px",
									},
									fontWeight: "bold",
								}}
							>
								Aloqa uchun
							</Typography>
						</Box>
						<a href="tel:+998975903309">
							<img
								src="https://i.pinimg.com/originals/68/85/87/688587d89bcaad9109cda401fed39b66.gif"
								alt="Computer man"
								className="telicon"
								// style="width:48px;height:48px;"
							/>
						</a>
					</Box>
				</Box>
			</Box>
		</Container>
	);
};
