import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import "./style.css";
import { Link } from "react-scroll";

import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

import { Box } from "@mui/material";
export default function SplitButton({ data, name, img, href }) {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleMenuItemClick = (event, index) => {
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};
	return (
		<React.Fragment>
			<ButtonGroup
				variant="text"
				ref={anchorRef}
				// aria-label="split button"
				// sx={{ textDecoration: "none" }}
			>
				<Button className="li">
					<Link activeClass="active" className="navtext1" spy to={href}>
						{name}
					</Link>
				</Button>
			</ButtonGroup>
			{data && (
				<Popper
					sx={{
						zIndex: 1,
					}}
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === "bottom" ? "center top" : "center bottom",
							}}
						>
							<Box id="pastkiqism">
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu" autoFocusItem>
										{data.map((option, index) => (
											<MenuItem
												key={index}
												// selected={index === selectedIndex}
												onClick={(event) => handleMenuItemClick(event, index)}
												id="btntext"
											>
												{option.title}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Box>
						</Grow>
					)}
				</Popper>
			)}
		</React.Fragment>
	);
}
