import { Box, Button, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import "../../components/Fulstek/style.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Navbar from "../../components/Navbar";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Chart from "./Chart";
export default () => {
	const navigate = useNavigate();
	const location = useLocation();

	// console.log(location);
	const data = location.state;
	return (
		<Box id="bor">
			<Navbar pag />
			<Box
				sx={{
					width: "100%",
					height: "100%",
					px: "10px",
					py: "5%",
				}}
			>
				<Container>
					<Box
						// id="Grid-Card2"
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							my: { xs: "20%", sm: "8%", md: "5%", lg: "0%", xl: "0%" },
						}}
					>
						<Button
							variant="text"
							sx={{
								color: "#000",
								textTransform: "none",
								fontWeight: 700,
								width: "100px",
								my: "10px",
								":hover": { backgroundColor: "transparent" },
							}}
							startIcon={
								<ArrowBackIcon
									sx={{
										color: "#000",
									}}
								/>
							}
							onClick={() => navigate("/")}
						>
							Orqaga
						</Button>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Box sx={{ textAlign: "center" }}>
										<Typography
											sx={{
												fontSize: {
													xl: "24px",
													lg: "24px",
													md: "22px",
													sm: "20px",
													xs: "20px",
												},
												fontWeight: "bold",
											}}
										>
											{data.name} dasturi
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={5} sm={4} md={3} lg={3} xl={3}>
									<Box
										sx={{
											width: "100%",
										}}
									>
										<img src={data.img} alt="" width="100%" />
										<Typography
											sx={{
												textAlign: "center",
												mt: "10px",
												fontSize: {
													xl: "24px",
													lg: "24px",
													md: "22px",
													sm: "20px",
													xs: "20px",
												},
												fontWeight: "bold",
											}}
										>
											{data.name}
										</Typography>
									</Box>
								</Grid>

								<Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
									<Box
										sx={{
											height: "100%",
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-between",
										}}
									>
										<Typography
											sx={{
												fontSize: {
													xl: "20px",
													lg: "20px",
													md: "18px",
													sm: "16px",
													xs: "16px",
												},
												textAlign: "justify",
											}}
										>
											{data.des}
										</Typography>
										<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
											<Typography sx={{ fontSize: "20px" }}> Sayt:</Typography>
											<Link
												href={data.link}
												target="_blank"
												sx={{ textDecoration: "none", fontSize: "20px" }}
											>
												{data.sayt}
											</Link>
										</Box>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Box>
					<Box sx={{ width: "100%" }}>
						<hr id="hrm" />
						<Grid container spacing={0}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Box sx={{ textAlign: "center", width: "100%", my: "2%" }}>
									<Typography
										sx={{
											fontSize: {
												xl: "24px",
												lg: "24px",
												md: "22px",
												sm: "20px",
												xs: "20px",
											},
											fontWeight: "bold",
										}}
									>
										{data.name}da yaratilgan mashxur ilovalar
									</Typography>
								</Box>
							</Grid>
							{data.madein.map((item, i) => (
								<Grid
									key={i}
									item
									xs={6}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									sx={{ display: "flex", justifyContent: "center" }}
								>
									<Link
										sx={{
											width: "40%",
											textAlign: "center",
											display: "flex",
											alignItems: "center",
											flexDirection: "column",
											textTransform: "none",
											color: "#000",
											":hover": { backgroundColor: "transparent" },
										}}
										href={item.link}
										target="_blank"
										underline="none"
									>
										<img src={item.img} alt="" id="ilovalar" />
										<Typography
											key={i}
											sx={{
												fontSize: {
													xl: "20px",
													lg: "20px",
													md: "18px",
													sm: "16px",
													xs: "16px",
												},
												fontWeight: "bold",
											}}
										>
											{item.text}
										</Typography>
									</Link>
								</Grid>
							))}
						</Grid>
					</Box>
					<hr id="hrm" />
					<Box
						sx={
							data.chartOn == 0
								? { display: "none" }
								: {
										display: "inline",
										textAlign: "center",
										width: "100%",
										my: "2%",
								  }
						}
					>
						<Typography
							sx={{
								fontSize: {
									xl: "24px",
									lg: "24px",
									md: "22px",
									sm: "20px",
									xs: "20px",
								},
								mb: "10px",
								fontWeight: "bold",
							}}
						>
							2022-yilda Dunyo dasturchilari bo'yicha eng koʻp foydalaniladigan{" "}
							{data.type}
						</Typography>

						<Box
							id="TableBody01"
							// sx={data.type == 0 ? { display: "none" } : { display: "inline" }}
						>
							<Chart data={data.chartOn} />
						</Box>
						<Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
							<Button
								variant="contained"
								sx={{
									textTransform: "none",
									fontWeight: 700,
									background: "#3AB548FF",
									mt: "1%",
									":hover": {
										background: "#3AB548FF",
									},
								}}
								endIcon={<ArrowForwardIcon />}
								href={data.chartlink}
								target="_blank"
							>
								Aniqlik
							</Button>
						</Box>
					</Box>
				</Container>
			</Box>
			{/* <SungiBulim pag /> */}
		</Box>
	);
};
