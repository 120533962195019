import { Box, Button, Popper, Typography } from "@mui/material";
import React, { useState } from "react";
import Til from "./Til";
import "./style.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Navbar from "./NavbarPart";
import logo from "../../assets/image/CodemyLogo2.png";
import PersonIcon from "@mui/icons-material/Person";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";

import { Link } from "react-scroll";
import { Container } from "@mui/system";
// const ref = React.useRef(null);

export default ({ pag }) => {
	const navigate = useNavigate();
	const lists = [
		{
			id: 5,
			title: "Biz bilan o`rganing",
			submenu: false,
			icon: "",
			link: "Backend",
		},
		{
			id: 6,
			title: "Tarmoqlar",
			submenu: false,
			icon: "",
			link: "meetthebg",
		},
		{
			id: 7,
			title: "O`quv yili",
			submenu: false,
			icon: "",
			link: "oquv",
		},
		{
			id: 8,
			title: "Ustozlar",
			submenu: false,
			icon: "",
			link: "ustoz",
		},
		{
			id: 9,
			title: "Manzil",
			submenu: false,
			icon: "",
			link: "manzil",
		},
	];

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [show, setShow] = React.useState(false);

	const handleClick = (event) => {
		setShow(!show);
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popper" : undefined;
	const [navbar, setNavbar] = useState(false);
	const changebg = () => {
		if (window.scrollY >= 10) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};
	window.addEventListener(`scroll`, changebg);

	return (
		<Box
			className={
				navbar
					? "navbarbig aktiv"
					: "navbarbig" && pag
					? "navbarbig aktiv"
					: "navbarbig"
			}
			sx={{
				py: { lg: 1, md: 1, sm: 1.5, xs: 2 },
				display: "flex",
			}}
		>
			<Container
				maxWidth="xl"
				sx={{ display: "flex", justifyContent: "space-between" }}
			>
				<Link
					to="/"
					underline="none"
					className="navBrand"
					onClick={() => navigate("/")}
				>
					<img
						// src="https://decimalchain.com/_nuxt/img/del_mobile.caaf033.svg"
						src={logo}
						alt="logo"
						id="codemylogo"
					/>
				</Link>

				<Box
					className="midlenav"
					sx={{
						display: {
							xl: "flex",
							lg: "flex",
							md: "flex",
							sm: "none",
							xs: "none",
						},
					}}
				>
					<Box className="leftnavbar">
						<Navbar name="Frontend" href={pag ? "#" : "Frontend"} />
						<Navbar name="Backend" href={pag ? "#" : "Backend"} />

						<Navbar name="Tarmoqlar" href={pag ? "#" : "meetthebg"} />
						<Navbar name="O'quv yili" href={pag ? "#" : "oquv"} />
						<Navbar name="Ustozlar" href={pag ? "#" : "ustoz"} />
						<Navbar name="Manzil" href={pag ? "#" : "manzil"} />
					</Box>

					<Box className="ringnavbar">
						<Til />
						{/* <Til data={imgs} /> */}
						<Button
							variant="contained"
							href={pag ? "" : "#manzil"}
							className={navbar ? "btnnav aktivbtn" : "btnnav"}
							sx={{ display: { md: "none", lg: "inline", xl: "inline" } }}
						>
							Ro'yxatdan o'tish
						</Button>
						<Button
							variant="contained"
							className={navbar ? "btnnav aktivbtn" : "btnnav"}
							sx={{ display: { md: "inline", lg: "none", xl: "none" } }}
						>
							<PersonIcon />
						</Button>
					</Box>
				</Box>

				<Box
					sx={{
						display: {
							xl: "none",
							lg: "none",
							md: "none",
							sm: "flex",
							xs: "flex",
						},
					}}
				>
					<Button onClick={handleClick} sx={{ color: "white" }}>
						{show ? <CloseIcon id="icon" /> : <MenuIcon id="icon" />}
					</Button>

					<Popper
						id={id}
						open={open}
						anchorEl={anchorEl}
						sx={{
							display: {
								xl: "none",
								lg: "none",
								md: "none",
								sm: "flex",
								xs: "flex",
							},
							zIndex: 10,
						}}
					>
						<Box id="listId">
							<List
								sx={{
									width: "100%",
									// color: "white",
									border: 0,
								}}
								component="nav"
								id="Listitembg"
							>
								{lists.map((item, i) => {
									return (
										<ListItemButton key={i}>
											<Link to={item?.link} onClick={handleClick}>
												<ListItemText primary={item?.title} />
											</Link>
										</ListItemButton>
									);
								})}
								<ListItemButton>
									<Button variant="contained" className="btnnav">
										Ro'yxatdan o'tish
									</Button>
								</ListItemButton>{" "}
							</List>
						</Box>
					</Popper>
				</Box>
			</Container>
		</Box>
	);
};
