import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Box } from "@mui/material";
import "../style.css";
export default function TriggersTooltips({ rasm, text, left, top, w }) {
	const [open, setOpen] = React.useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	return (
		<Box>
			<ClickAwayListener onClickAway={handleTooltipClose}>
				<Tooltip
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableTouchListener
					title={text}
				>
					<img
						src={rasm}
						onClick={handleTooltipOpen}
						alt=""
						id="oyicon"
						width={w}
						style={{ position: "absolute", left: `${left}`, top: `${top}` }}
					/>
				</Tooltip>
			</ClickAwayListener>
		</Box>
	);
}
