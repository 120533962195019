import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./style.css";
import WaterWave from "react-water-wave";
import { useNavigate } from "react-router-dom";
import rasm from "../../assets/image/aaaaaaa.png";
export default () => {
	const navigate = useNavigate();
	return (
		<Box id="Bigbox">
			{/* <WaterWave
				dropRadius={20}
				perturbance={1}
				interactive={true}
				color={"red"}
			>
				{() => ( */}
			<Box>
				<Box
					sx={{
						width: "100%",
						py: { xl: "11%", lg: "8%", md: "12%", sm: "6%", xs: "28%" },
					}}
					id="selek"
				>
					<Container maxWidth="lg" style={{ padding: 0 }}>
						<Box className="o1a"></Box>
						<Box className="o2"></Box>
						<Box className="o3"></Box>
						<Grid
							container
							sx={{
								width: "100%",
								px: { lg: "6%", md: "32px", sm: "12%" },
								mx: 0,
							}}
							spacing={1}
							columnSpacing={2}
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={6}
								lg={6}
								xl={6}
								// sx={{ border: "1px solid red" }}
							>
								<Box
									sx={{
										my: { xl: 0, lg: 0, md: "10%", sm: "6%" },
										// border: "1px solid red",
										mt: "20px",
									}}
								>
									<Typography
										data-aos="fade-up"
										variant="h1"
										sx={{
											fontSize: {
												lg: "65px",
												md: "57px",
												sm: "45px",
												xs: "27px",
											},
											mb: { lg: "5%", md: "5%", sm: "7%", xs: "7%" },
											display: { sm: "flex", md: "inline-block" },
											pt: "12px",
										}}
										id="boshtex"
									>
										Kelajagingizni biz bilan yarating!
									</Typography>
									<Typography
										data-aos="fade-up"
										className="headertexttwo"
										sx={{
											fontSize: { lg: "28px", md: "18px", sm: "25px" },
											mb: { md: "10%", sm: 0, xs: "10%" },
											color: "black",
											fontWeight: "bold",
										}}
									>
										Biz bilan o'zingizni qaytadan kashf qiling!
									</Typography>
								</Box>
								<Grid
									container
									sx={{
										width: "100%",
										display: "flex",
										flexDirection: {
											lg: "row",
											xl: "row",
											md: "row",
											sm: "column-reverse",
											xs: "column-reverse",
										},
									}}
									spacing={2}
								>
									{/* <Grid
										item
										xs={12}
										sm={12}
										md={5}
										lg={5}
										xl={5}
										// sx={{ border: "2px solid red" }}
									>
										<Button
											variant="contained"
											endIcon={<ArrowForwardIcon id="icon1" />}
											className="headerbtn"
											sx={{
												maxWidth: { lg: "202px", md: "175px", xs: "175px" },
												height: { md: "72px", xs: "53px" },
												py: { sm: "2.9%", md: "10%", lg: "12%" },
											}}
											data-aos="fade-up"
										>
											Qabul
										</Button>
									</Grid> */}
									<Grid
										item
										xs={12}
										sm={12}
										md={7}
										lg={7}
										xl={7}
										// sx={{ border: "2px solid red" }}
									>
										<Button
											variant="outlined"
											className="headerbtn2"
											sx={{
												height: { md: "72px", xs: "53px" },
												maxWidth: { md: "230px", xs: "194px" },

												boxShadow:
													" 0 8px 8px -4px rgb(0 187 255 / 6%), 0 16px 24px 0 rgb(0 187 255 / 12%), 0 2px 4px -1px rgb(27 10 82 / 6%), 0 0 1px 0 rgb(0 187 255 / 12%), inset 0 2px 6px 0 rgb(0 187 255 / 40%);",
												":hover": {
													boxShadow:
														" 0 8px 8px -4px rgb(0 187 255 / 6%), 0 16px 24px 0 rgb(0 187 255 / 12%), 0 2px 4px -1px rgb(27 10 82 / 6%), 0 0 1px 0 rgb(0 187 255 / 12%), inset 0 2px 6px 0 rgb(0 187 255 / 40%);",
												},
											}}
											href="#manzil"
											data-aos="fade-up"
										>
											Ro'yxatdan o'tish
										</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Box
									sx={{
										white: "100%",
										display: "flex",
										justifyContent: "center",
										position: "relative",
										// maxWidth: "465px",
										// border: "2px solid red",
										mx: { sm: "15%", md: "6%", lg: 0, xl: 0 },
										mr: { md: "10%", lg: 0, xl: 0 },
										mt: { lg: 0, xl: 0 },
									}}
								>
									<Grid container>
										<Grid
											item
											xs={6}
											sm={6}
											md={6}
											lg={6}
											xl={6}
											data-aos="fade-up"
										>
											<Box
												sx={{
													position: "relative",
												}}
											>
												<img src={rasm} id="telfonrasm" alt="" />
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Box>
			{/* )}
			</WaterWave> */}
		</Box>
	);
};
