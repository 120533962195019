import "./style.css";

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { useState, useEffect } from "react";

import InputMask from "react-input-mask";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import emailjs from "@emailjs/browser";
import Modal from "@mui/material/Modal";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const MyMask = ({ value, onChange }) => {
	return (
		<InputMask
			mask="(0)999 999 99 99"
			value={value}
			onChange={onChange}
			maskChar=" "
		/>
	);
};

export default () => {
	const form = React.useRef();
	const [inputs, setInputs] = React.useState({
		ism: "",
		familya: "",
		tel: "",
		yozuv: "",
	});
	const [error, setError] = useState(false);
	const [open, setOpen] = React.useState(false);
	let handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setError(false);
		setInputs({
			ism: "",
			familya: "",
			tel: "",
			yozuv: "",
		});
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		emailjs
			.sendForm(
				"service_eqhm07u",
				"template_ps7efx8",
				form.current,
				"ml2JMK9RR2_QdhuUr"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		if (
			inputs.tel.length == 0 ||
			inputs.ism.length == 0 ||
			inputs.familya.length == 0 ||
			inputs.yozuv.length == 0
		) {
			setError(true);
		}
		if (inputs.ism && inputs.familya && inputs.tel && inputs.yozuv) {
			console.log("--------------", inputs);
			handleOpen();
			// sendEmail();
		}
	};

	const handleChange = (event, inputname) => {
		const name = inputname || event.target.name;
		const value = event.target.value;
		setInputs((values) => ({ ...values, [name]: value }));
	};

	return (
		<form ref={form} onSubmit={handleSubmit}>
			<Typography
				sx={{
					textAlign: "center",
					fontWeight: 600,
					fontSize: "28px",
					mb: "4%",
					fontSize: { xs: "22px", sm: "22px", md: "28px", lg: "28px" },
				}}
			>
				Biz siz bilan bog'lanamiz !
			</Typography>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					gap: 1,
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<Typography sx={{ fontWeight: "bold" }}>Ismingiz</Typography>

						<TextField
							id="ism"
							// required
							type="text"
							name="ism"
							value={inputs.ism}
							onChange={handleChange}
							color={error && inputs.ism.length <= 0 ? "error" : "success"}
							placeholder="Ism"
							fullWidth
							focused
						/>

						{error && inputs.ism.length <= 0 ? (
							<Typography id="ogoh">Iltimos Ismingizni kiriting !</Typography>
						) : (
							""
						)}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<Typography sx={{ fontWeight: "bold" }}>Familyangiz</Typography>

						<TextField
							id="familya"
							fullWidth
							// required
							placeholder="Familya"
							color={error && inputs.familya.length <= 0 ? "error" : "success"}
							focused
							type="text"
							name="familya"
							value={inputs.familya}
							onChange={handleChange}
						/>

						{error && inputs.familya.length <= 0 ? (
							<Typography id="ogoh">
								Iltimos Familyangizni kiriting !
							</Typography>
						) : (
							""
						)}
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography sx={{ fontWeight: "bold" }}>
							Telefon raqamingiz
						</Typography>

						<InputMask
							mask="(99)-999-99-99"
							maskPlaceholder="dd/mm/yy"
							value={inputs.tel}
							name="tel"
							onChange={(e) => handleChange(e, "tel")}
						>
							{(e) => (
								<TextField
									placeholder="(99)-999-99-99"
									fullWidth
									focused
									type="text"
									name="tel"
									color={error && inputs.tel.length <= 0 ? "error" : "success"}
								/>
							)}
						</InputMask>
						{error && inputs.tel.length <= 0 ? (
							<Typography id="ogoh">
								Iltimos telefon raqamingizni kiriting !
							</Typography>
						) : (
							""
						)}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography sx={{ fontWeight: "bold" }}>Murojatingiz</Typography>
						<TextField
							id="yozuv"
							name="yozuv"
							placeholder="Murojatingiz ..."
							style={{ width: "100%" }}
							value={inputs.yozuv}
							onChange={handleChange}
							focused
							multiline
							color={error && inputs.yozuv.length <= 0 ? "error" : "success"}
						/>
						{error && inputs.yozuv.length <= 0 ? (
							<Typography id="ogoh">
								Iltimos izoh yoki murojatingizni kiriting !
							</Typography>
						) : (
							""
						)}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Button type="submit" variant="contained" fullWidth id="loginbtn">
							Yuborish
						</Button>
					</Grid>
				</Grid>

				<Modal
					open={open}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: 1,
								pt: "3%",
							}}
						>
							<CheckCircleIcon sx={{ color: "#3AB548FF", fontSize: "100px" }} />
							<Typography sx={{ fontWeight: "bold" }}>
								Siz ma'lumotlaringiz qabul qilindi !
							</Typography>
							<Typography sx={{ fontWeight: "bold" }}>
								Biz siz bilan bog`lanamiz{" "}
							</Typography>
							<Button
								type="button"
								variant="outlined"
								onClick={handleClose}
								id="loginbtn1"
							>
								Ok
							</Button>
						</Box>
					</Box>
				</Modal>
			</Box>
		</form>
	);
};
