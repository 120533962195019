import React from "react";
import "./style.css";
import Box from "@mui/material/Box";

import { Typography } from "@mui/material";

import BeenhereIcon from "@mui/icons-material/Beenhere";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import CountUp from "react-countup";
const uchcard = [
	{
		icon: <BeenhereIcon id="cardicon" />,
		son: "300",

		des: "Bizni tanlaganlar",
		mb: "0px",
		class: "Child",
	},
	{
		icon: <HowToRegIcon id="cardicon" />,
		son: "40",
		foiz: "%",

		des: "Bitirganlar",
		mb: "10px",
		class: "Child Child2",
	},
	{
		icon: <LaptopMacIcon id="cardicon" />,
		son: "100",

		foiz: "%",
		des: "Ish bilan taminlanganlar",
		mb: "0px",
		class: "Child",
	},
];
export default () => {
	return (
		<Box id="natija">
			<Box sx={{ flexGrow: 1, pt: "120px" }} className="threeCard">
				<Box className="GridFlex" data-aos="fade-up">
					{uchcard.map((item, i) => (
						<Box className={item.cls || item.class} key={i}>
							<Box className="ChIcon">{item.icon}</Box>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Typography className="ChNum">
									<CountUp
										className="account-balance"
										start={0}
										end={item.son}
										duration={10}
										useEasing={true}
										separator=","
										useGrouping={true}
									/>
								</Typography>

								<Typography fontSize="30px">{item.foiz}</Typography>
							</Box>

							<Box>
								<Typography className="ChDesc2">{item.des}</Typography>
							</Box>
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
};
