import React, { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Root from "./root";
import "./App.css";
import { BrowserRouter, Router, useLocation } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};
root.render(
	<BrowserRouter>
		<ScrollToTop />
		<Root />
	</BrowserRouter>
);
