import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "./leadership.css";
import Abdulajon from "../../assets/image/Domlalar/Abdullajon domla.jpg";
import Zokirjon from "../../assets/image/Domlalar/zokirjon domla.jpg";
import Nodirjon from "../../assets/image/Domlalar/Nodirjon domla.jpg";
import Odiljon from "../../assets/image/Domlalar/Odiljon domla.jpg";
import Bobur from "../../assets/image/Domlalar/Bobur domla.jpg";
import Xusrav from "../../assets/image/Domlalar/Xusrav .jpg";
import Azamatjon from "../../assets/image/Domlalar/azamatjon.jpg";
import Xayrullo from "../../assets/image/Domlalar/Xayrullo.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 900 },
		items: 4,
	},
	desktop: {
		breakpoint: { max: 900, min: 700 },
		items: 3,
	},

	tablet: {
		breakpoint: { max: 700, min: 420 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 420, min: 0 },
		items: 1,
	},
};
const card = [
	{
		img: Abdulajon,
		name: "Abdullajon Muxtorov",
		title: "Project Manager",
		ishjoy: "ELEKTRON HOKIMIYATNI RIVOJLANTIRISH MARKAZI",
		tajriba: "2 yil",
	},
	{
		img: Zokirjon,
		name: "Zokirjon Xoliqov",
		title: "Frontend Dasturchi",
		ishjoy: "ELEKTRON HOKIMIYATNI RIVOJLANTIRISH MARKAZI",

		tajriba: "4 yil",
	},
	{
		img: Nodirjon,
		name: "Nodirbek Abdumansurov",
		title: "Frontend Dasturchi",
		ishjoy: "ELEKTRON HOKIMIYATNI RIVOJLANTIRISH MARKAZI",

		tajriba: "3 yil",
	},
	{
		img: Odiljon,
		name: "Odiljon Sultonov",
		title: "Backend dasturchi",
		ishjoy: "ELEKTRON HOKIMIYATNI RIVOJLANTIRISH MARKAZI",

		tajriba: "3 yil",
	},
	{
		img: Bobur,
		name: "Boburjon Mahammadjonov",
		title: "Full Stack dasturchi ",
		ishjoy: "ELEKTRON HOKIMIYATNI RIVOJLANTIRISH MARKAZI",

		tajriba: "4 yil",
	},
	{
		img: Xusrav,
		name: "Xusrav Muxtorov",
		title: "Mobil dasturchi",
		ishjoy: "Codemy dasturlash akademiyasi",
		tajriba: "2 yil",
	},
	{
		img: Azamatjon,
		name: "Azamatbek Nabiyev",
		title: "Mentor",
		ishjoy: "Codemy dasturlash akademiyasi",
		tajriba: "1 yil",
	},
	{
		img: Xayrullo,
		name: "Xayrullo Ergashev",
		title: "Mentor",
		ishjoy: "Codemy dasturlash akademiyasi",
		tajriba: "6 oy",
	},
];
export default () => {
	return (
		<>
			<Box className="Team" id="ustoz" sx={{ pt: "80px" }}>
				<Box className="TeamBOD">
					<Box className="fonbox1 pos"></Box>
					{/* <Box className="fonbox2 pos"></Box> */}
					<Box className="fonbox3 pos"></Box>
					<Box className="fonbox4 pos"></Box>
					<Box className="fonbox5 pos"></Box>

					<Box className="TeamTextPartlll" sx={{ my: "15px" }}>
						<Typography className="TeamTExt1">Ustozlar</Typography>
						<Typography
							className="TeamText33"
							sx={{
								fontSize: { xs: "24px", sm: "24px", md: "30px", lg: "36px" },
							}}
							data-aos="fade-up"
						>
							Codemy dasturlash akademiyasi ustozlari
						</Typography>
					</Box>
					<Box
						className="TeamCardPart"
						sx={{
							display: {
								xs: "none",
								sm: "none",
								md: "none",
								lg: "grid",
								xl: "grid",
							},
						}}
						data-aos="fade-up"
					>
						{card.map((row, ind) => {
							return (
								<Box className="TeamCards" sx={{ p: 0 }} key={ind}>
									<Box>
										<img src={row.img} className="CardImagesL" alt="" />
									</Box>
									<Box sx={{ px: "20px", py: "10px" }} className="ustozyozuv">
										<Typography className="CardNameL">{row.name}</Typography>
										<Typography className="CardPosLL">{row.title}</Typography>
										<Typography className="CardDescLL">{row.ishjoy}</Typography>
										<Typography className="CardDescLL">
											Ish tajribasi: {row.tajriba}
										</Typography>
									</Box>
								</Box>
							);
						})}
					</Box>
				</Box>
			</Box>
			<Box
				className="TeamBOD2"
				sx={{
					display: {
						xs: "inline",
						sm: "inline",
						md: "inline",
						lg: "none",
						xl: "none",
					},
				}}
			>
				<Container>
					<Carousel responsive={responsive} id="respbox">
						{card.map((row, ind) => {
							return (
								<Box className="TeamCards2" sx={{ p: 0 }} key={ind}>
									<Box>
										<img src={row.img} className="CardImagesL" alt="" />
									</Box>
									<Box sx={{ px: "20px" }} className="ustozyozuv">
										<Typography className="CardNameL">{row.name}</Typography>
										<Typography className="CardPosLL">{row.title}</Typography>
										<Typography className="CardDescLL">{row.ishjoy}</Typography>
										<Typography className="CardDescLL">
											Ish tajribasi: {row.tajriba}
										</Typography>
									</Box>
								</Box>
							);
						})}
					</Carousel>
				</Container>
			</Box>
		</>
	);
};
