import { Box } from "@mui/system";
import React from "react";
import "./style.css";
import HoverIcon from "./HoverIcon";
import { Typography } from "@mui/material";
import Chart from "./Chart";
import jop from "../../assets/image/aw.png";
import javascript from "../../assets/image/logo-javascript.svg";
import CodemyLogo from "../../assets/image/CodemyLogo.png";
const icondata = [
	{
		icon: CodemyLogo,
		text: "Codemy",
		left: "2%",
		top: "86%",
		w: "50px",
	},
	{
		icon: "https://cdn.worldvectorlogo.com/logos/html-1.svg",
		text: "HTML",
		left: "8%",
		top: "80%",
		w: "52px",
	},
	{
		icon: "https://cdn.worldvectorlogo.com/logos/css-3.svg",
		text: "Css style",
		left: "11%",
		top: "80%",
		w: "52px",
	},
	{
		icon: "https://cdn.worldvectorlogo.com/logos/css-3.svg",
		text: "Css style",
		left: "18%",
		top: "72%",
		w: "52px",
	},
	{
		icon: "https://cdn.worldvectorlogo.com/logos/bootstrap-4.svg",
		text: "Bootstrap",
		left: "21%",
		top: "72%",
		w: "52px",
	},

	{
		// icon: "https://www.freepnglogos.com/uploads/javascript-png/javascript-vector-logo-yellow-png-transparent-javascript-vector-12.png",
		icon: javascript,
		text: "JavaScript",
		left: "29%",
		top: "62%",
		w: "52px",
	},
	{
		// icon: "https://www.freepnglogos.com/uploads/javascript-png/javascript-vector-logo-yellow-png-transparent-javascript-vector-12.png",
		icon: javascript,
		text: "JavaScript",
		left: "39%",
		top: "52%",
		w: "52px",
	},
	{
		// icon: "https://t4.ftcdn.net/jpg/00/26/63/61/360_F_26636186_5lTpbFzwAG1yVZoxTMhD5XAygji9C2K7.jpg",
		icon: "https://cdn2.iconfinder.com/data/icons/health-care-5/512/cyborg_brain-512.png",
		text: "Algoritim",
		left: "48%",
		top: "43%",
		w: "52px",
	},
	{
		icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png",
		text: "React",

		left: "58%",
		top: "34%",
		w: "52px",
	},
	{
		icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png",
		text: "React",

		left: "67%",
		top: "25%",
		w: "52px",
	},

	{
		icon: "https://media.istockphoto.com/vectors/system-software-update-and-upgrade-concept-loading-process-in-laptop-vector-id824923460?k=20&m=824923460&s=170667a&w=0&h=yyNVvCZ5vxRra37y5bSZyzxSXp9uAknRT4qIAil2vxg=",
		text: "Amaliyot",

		left: "77%",
		top: "15%",
		w: "52px",
	},
	{
		icon: "https://media.istockphoto.com/vectors/system-software-update-and-upgrade-concept-loading-process-in-laptop-vector-id824923460?k=20&m=824923460&s=170667a&w=0&h=yyNVvCZ5vxRra37y5bSZyzxSXp9uAknRT4qIAil2vxg=",
		text: "Amaliyot",

		left: "86%",
		top: "5%",
		w: "52px",
	},

	{
		icon: jop,
		text: "Juniyor dasturchi",

		left: "92%",
		top: "-8%",
		w: "100px",
	},
];
export default () => {
	return (
		<Box id="oquv">
			<Box sx={{ py: "60px" }}>
				<Box>
					<Typography
						sx={{
							textAlign: "center",
							fontWeight: "bold",
							fontSize: { xs: "24px", sm: "24px", md: "30px", lg: "36px" },
							my: "2%",
						}}
						id="uquvyili"
						data-aos="fade-up"
					>
						O'quv yili davomiyligi
					</Typography>
					<Box className="TableBody01" data-aos="fade-up-right">
						<Box className="TableBody">
							<Chart />
							<Box>
								{icondata.map((item, i) => (
									<HoverIcon
										key={i + 1}
										rasm={item.icon}
										text={item.text}
										left={item.left}
										top={item.top}
										w={item.w}
									/>
								))}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
