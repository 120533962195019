import { Box, Typography, Grid, Button, Link } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import "./style.css";

import rasmlo2 from "../../assets/image/logatsiya 2.png";
import Login from "../Login";
export default () => {
	return (
		<Container maxWidth="lg" id="manzil" sx={{ py: "100px" }}>
			<Box sx={{ width: "100%", px: { md: "10px", lg: "45px", xl: "45px" } }}>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box>
						<Box>
							<Grid container spacing={10} data-aos="fade-up">
								<Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
									<Box sx={{ width: "100%" }}>
										<Login />
										<Typography
											sx={{
												width: "100%",
												fontSize: "17px",
												fontWeight: 700,
												my: "1%",
											}}
										>
											Farg'ona viloyati , Rishton tumani Oq-yer qishlog'ida
											joylashgan
											<br />
											<span
												style={{
													color: "#3AB54AFF",
													marginLeft: "2px",
													marginRight: "2px",
												}}
											>
												Codemy Dasturlash Akademiyasi
											</span>
											<br />
											Manzil: Tibbiyot Texnikumi
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={7} xl={7} id="imgGrid">
									<Button
										href="https://www.google.com/maps/place/Codemy/@40.3743544,71.2646374,18.29z"
										target="_blank"
										sx={{
											":hover": { bgcolor: "#fffffffe", boxShadow: "none" },
											":active": {
												bgcolor: "transparent",
												boxShadow: "none",
											},
										}}
										className="geeks"
									>
										<img src={rasmlo2} alt="" id="karta" />
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			</Box>
		</Container>
	);
};
